import { useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { AuthContext } from 'src/context/AuthProvider';
import MotusLogo from 'public/images/motus_logo.svg';
import NavMenu from './NavMenu';
import SettingsMenu from './SettingsMenu';
import MobileMenu from './MobileMenu';
import InfoDialog from 'src/components/Modals/InfoDialog';

export default function AuthenticatedAppBar() {
  const auth = useContext(AuthContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        '& .MuiTypography-root': { color: '#325127' },
      }}
    >
      <AppBar
        color="default"
        position="fixed"
        className="no-print"
        sx={(theme) => ({
          backgroundColor: '#ffffff',
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          height: '70px',
        })}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: '70px',
            maxWidth: '1700px',
            width: '100%',
            margin: '0 auto',
            px: { xs: '16px' },
          }}
        >
          <Link href="/opinmal" passHref>
            <Image src={MotusLogo} alt="Greiðandavefur Motus" style={{ width: '100px', margin: '3px 25px 0px 0px' }} />
          </Link>
          {auth.isLoggedIn && !auth.needsToAcceptTerms && auth.settings && (
            <Box
              sx={(theme) => ({
                display: 'flex',
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <NavMenu />
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {auth.isLoggedIn && !auth.needsToAcceptTerms && auth.settings && (
            <Box
              sx={(theme) => ({
                mt: '5px',
                display: 'none',
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
              key="mobileMenu"
            >
              <MobileMenu />
            </Box>
          )}
          {auth.isLoggedIn && (
            <Box sx={{ mt: '5px' }} key="settingsMenu">
              <SettingsMenu />
            </Box>
          )}
          {auth.isLoggedIn && <InfoDialog />}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
