import PropTypes from 'prop-types';

export const claim = PropTypes.shape({
  claimId: PropTypes.string,
  claimNumber: PropTypes.string,
  product: PropTypes.string,
  claimantNumber: PropTypes.string,
  claimantName: PropTypes.string,
  claimantSsn: PropTypes.string,
  isOpen: PropTypes.bool,
  hasActiveAgreement: PropTypes.bool,
  principal: PropTypes.number,
  defaultInterest: PropTypes.number,
  claimantCost: PropTypes.number,
  collectionCost: PropTypes.number,
  paidPrincipal: PropTypes.number,
  paidDefaultInterest: PropTypes.number,
  paidClaimantCost: PropTypes.number,
  paidCollectionCost: PropTypes.number,
  paidTotal: PropTypes.number,
  totalRemaining: PropTypes.number,
  closedDate: PropTypes.string,
  oldestUnpaidDueDate: PropTypes.string,
  isPaymentAllowed: PropTypes.bool,
  isAgreementAllowed: PropTypes.bool,
  postponementMaxDays: PropTypes.number,
  postponedUntilDate: PropTypes.string,
  isInClosingProcess: PropTypes.bool,
});

export const paymentHistoryItem = PropTypes.shape({
  claimantId: PropTypes.string,
  claimantName: PropTypes.string,
  claimId: PropTypes.string,
  claimNumber: PropTypes.string,
  product: PropTypes.string,
  totalRemaining: PropTypes.number,
  closedDate: PropTypes.string,
  principal: PropTypes.number,
  defaultInterest: PropTypes.number,
  claimantCost: PropTypes.number,
  collectionCost: PropTypes.number,
  totalPayment: PropTypes.number,
  paymentDate: PropTypes.string,
});

export const paymentGroup = PropTypes.shape({
  paymentNumber: PropTypes.number,
  paymentDate: PropTypes.string,
  totalPayment: PropTypes.number,
  items: PropTypes.arrayOf(paymentHistoryItem),
});

export const history = PropTypes.shape({
  codeText: PropTypes.string,
  date: PropTypes.string,
});

export const invoice = PropTypes.shape({
  description: PropTypes.string,
  invoiceNumber: PropTypes.string,
  dueDate: PropTypes.string,
  principal: PropTypes.number,
});

export const payment = PropTypes.shape({
  claimId: PropTypes.string,
  claimNumber: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  totalPayment: PropTypes.number,
});

export const agreementInstallment = PropTypes.shape({
  number: PropTypes.number,
  agreementId: PropTypes.number,
  dueDate: PropTypes.string,
  administrationFee: PropTypes.number,
  amountDue: PropTypes.number,
  processingDate: PropTypes.string,
  processingMethod: PropTypes.string,
  hasProcessingError: PropTypes.bool,
  paymentSlipDueDate: PropTypes.string,
  paymentCardAuthCode: PropTypes.string,
  isPaid: PropTypes.bool,
});

export const claimminorinfo = PropTypes.shape({
  number: PropTypes.string,
  claimantName: PropTypes.string,
  productType: PropTypes.string,
});

export const agreement = PropTypes.shape({
  id: PropTypes.number,
  timestamp: PropTypes.string,
  paymentMethod: PropTypes.string,
  paid: PropTypes.string,
  closed: PropTypes.string,
  defaulted: PropTypes.string,
  isActive: PropTypes.bool,
  pdfName: PropTypes.string,
  claimInfos: PropTypes.arrayOf(claimminorinfo),
  installments: PropTypes.arrayOf(agreementInstallment),
});

export const claimAttachment = PropTypes.shape({
  id: PropTypes.number,
  filename: PropTypes.string,
  timestamp: PropTypes.string,
  description: PropTypes.string,
});

const kommiUser = PropTypes.shape({
  scope: PropTypes.string,
});

const issueAttachment = PropTypes.shape({
  filename: PropTypes.string,
  origin: PropTypes.string,
});

const issueMessage = PropTypes.shape({
  id: PropTypes.number,
  timestamp: PropTypes.string,
  sender: kommiUser,
  messageType: PropTypes.string,
  text: PropTypes.string,
  attachments: PropTypes.arrayOf(issueAttachment),
});

const claimInfo = PropTypes.shape({
  id: PropTypes.number,
  claimId: PropTypes.string,
  claimNumber: PropTypes.string,
  claimantName: PropTypes.string,
  product: PropTypes.string,
});

export const issue = PropTypes.shape({
  id: PropTypes.number,
  claimInfos: PropTypes.arrayOf(claimInfo),
  payerName: PropTypes.string,
  payerSsn: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  seen: PropTypes.bool,
  createdDate: PropTypes.string,
  lastUpdated: PropTypes.string,
  closedDate: PropTypes.string,
  initialMessage: issueMessage,
  outcome: issueMessage,
  messages: PropTypes.arrayOf(issueMessage),
});

export const paymentOptions = PropTypes.shape({
  maxNumberOfInstallments: PropTypes.number.isRequired,
  isForAgreement: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  supplementaryClaimIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const installment = PropTypes.shape({
  dueDate: PropTypes.string,
  defaultInterest: PropTypes.number,
  amountDue: PropTypes.number,
  administrationFee: PropTypes.number,
  remaining: PropTypes.number,
});

export const paymentPlan = PropTypes.shape({
  claimIds: PropTypes.arrayOf(PropTypes.string),
  amountDueNow: PropTypes.number,
  amountDueLater: PropTypes.number,
  principalAmount: PropTypes.number,
  interestAmount: PropTypes.number,
  secondaryCollectionCost: PropTypes.number,
  legalCollectionCost: PropTypes.number,
  claimantCost: PropTypes.number,
  agreementFee: PropTypes.number,
  defaultInterestTotal: PropTypes.number,
  administrationFeeTotal: PropTypes.number,
  installments: PropTypes.arrayOf(installment),
});

export const paymentCard = PropTypes.shape({
  cardNumber: PropTypes.string,
  expMonth: PropTypes.string,
  expYear: PropTypes.string,
  cvc: PropTypes.string,
});

export const criteria = PropTypes.shape({
  claimsOrderBy: PropTypes.string,
  claimsOrderDesc: PropTypes.bool,
});

export const resultObject = PropTypes.shape({
  isLoading: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  error: PropTypes.object,
});

export const paymentTypes = {
  paymentSlip: 'PaymentSlip',
  paymentCard: 'PaymentCard',
};

export const payerEmail = PropTypes.shape({
  id: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  getNotifications: PropTypes.bool.isRequired,
  confirmed: PropTypes.string,
});

export const payerPhone = PropTypes.shape({
  id: PropTypes.number.isRequired,
  countryCode: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  getNotifications: PropTypes.bool.isRequired,
});

export const error = PropTypes.shape({
  global: PropTypes.bool,
  message: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
});

export const authenticatedCard = PropTypes.shape({
  mdStatus: PropTypes.number.isRequired,
  xid: PropTypes.string.isRequired,
  cavv: PropTypes.string.isRequired,
  md: PropTypes.string.isRequired,
  dsTransId: PropTypes.string.isRequired,
});
