import { useState } from 'react';

import Link from 'next/link';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

const StyledMenuItem = ({ onClick, children }) => (
  <MenuItem
    onClick={onClick}
    sx={{
      py: '12px',
      color: '#325127',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '1.75em',
      textTransform: 'uppercase',
    }}
  >
    {children}
  </MenuItem>
);

export default function MobileMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  function handleClick(event) {
    event.currentTarget.blur();
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return [
    <IconButton
      aria-owns={isOpen ? 'mobile-menu' : null}
      aria-haspopup="true"
      aria-label="Leiðarkerfi"
      key="mobileMenuButton"
      onClick={handleClick}
      sx={(theme) => ({ p: '8px', mx: '4px', color: '#fff', [theme.breakpoints.down('sm')]: { mx: '2px', p: '6px' } })}
      size="large"
    >
      <MenuIcon sx={{ color: '#325127', fontSize: '32px' }} />
    </IconButton>,
    <Menu
      id="mobile-menu"
      key="mobileMenu"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      sx={(theme) => ({ m: '0px', mr: '32px', display: 'none', [theme.breakpoints.down('md')]: { display: 'block' } })}
    >
      <StyledMenuItem onClick={handleClose}>
        <Link href="/opinmal">Mál í innheimtu</Link>
      </StyledMenuItem>
      <Divider />
      <StyledMenuItem onClick={handleClose}>
        <Link href="/greiddmal">Greidd mál</Link>
      </StyledMenuItem>
      <Divider />
      <StyledMenuItem onClick={handleClose}>
        <Link href="/innborganir">Innborganir</Link>
      </StyledMenuItem>
      <Divider />
      <StyledMenuItem onClick={handleClose}>
        <Link href="/innheimtubref">Innheimtubréf</Link>
      </StyledMenuItem>
      <Divider />
      <StyledMenuItem onClick={handleClose}>
        <Link href="/samskipti">Samskipti</Link>
      </StyledMenuItem>
    </Menu>,
  ];
}
